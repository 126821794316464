<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px">
      <CCol><b>Bahan Pemuridan Sion</b></CCol>
    </CRow>

    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_module="{item}">
          <td v-if="item.pdfUrl">
            <CLink
              :href="item.pdfUrl"
              target="_blank">
              {{ item.moduleName }}
            </CLink>
          </td>
          <td v-else>
            {{ item.moduleName }}
          </td>
        </template>
      </CDataTable>
    </CRow>
  </div>

</div>
</template>

<script>
  import api from "../../api/api"

  const fields = [
    { key: 'semester', label: 'Semester', _style:'min-width:70px' },
    { key: 'moduleNo', label: 'Modul', _style:'min-width:60px' },
    { key: 'show_module', label: 'Nama', _style:'min-width:100px' },
    { key: 'nbMeeting', label: '#sesi', _style:'min-width:40px;' },
    { key: 'objective', label: 'Keterangan', _style:'min-width:100px;' },
    //{ 
    //  key: 'show_details', 
    //  label: '', 
    //  _style: 'width:1%', 
    //  sorter: false, 
    //  filter: false
    //}
  ]

  export default {
    name: 'Discipleship',
    data () {
      return {
        showLoading: false,
        items: [],
        fields,
        details: [],
        currentPeriod: '',
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else
        this.listBahanPA()
    },

    methods: {
      async listBahanPA () {
        this.showLoading = true
        this.items = []
        let apiRes = await api.listBahanPA("ALL")
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.currentPeriod = apiRes.data.currentPeriodName
          this.items = apiRes.data.items
        } 
        else {
          console.log("listBahanPA ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      getBadge (status) {
        switch (status) {
          case 'ADMIN': return 'success'
          default: 'primary'
        }
      },

    }
  }
</script>
